<template>
  <!-- 编码:lzq -->
  <div>
    <main>
      <!-- 头部横幅 -->
      <div class="banner">
        <div class="banner1">
          <img v-lazy="require('../assets/imgs/cultural_activity/banner.webp')" alt="" />
        </div>
        <!-- 横幅内容区域 -->
        <div class="banner2">
          <h2 class="title">文化活动</h2>
          <p class="introduce">
            大运河文化，是一种社会现象，是大运河自开凿以来长期创造形成的产物，又是一种历史现象，是运河流域社会历史的积淀物
          </p>
        </div>
      </div>
      <p class="title_h2">活动优点</p>
      <div class="play">
        <div class="play_item">
          <div>
            <img v-lazy="require('../assets/imgs/cultural_activity/gand_4.2.webp')" alt="" />
          </div>
          <p>景区有故事，文化有传承</p>
        </div>
        <div class="play_item">
          <div>
            <img v-lazy="require('../assets/imgs/cultural_activity/gand_4.3.webp')" alt="" />
          </div>
          <p>景区活动的实时更新，节日有活动，日常有节目</p>
        </div>
      </div>
      <!-- 大运河文化传承介绍区域 -->
      <div class="smriti">
        <h3 class="title_h3">大运河文化传承介绍</h3>
        <div class="content">
          <div class="images">
            <img v-lazy="require('../assets/imgs/cultural_activity/gand_12.webp')" alt="" />
          </div>
          <div class="spans">
            <span>
              <p></p>
              物态文化层：物质生产活动在大运河存留的财富总和，可触知的大运河文化物质实体
            </span>
            <span>
              <p></p>
              制度文化层：因为运河经济实践约定俗成的社会行为规范，有可持续性，例如-当代运河还在通航
            </span>
            <span>
              <p></p>
              行为文化层：大运河是在人际交往，以礼俗、民俗、风俗等形态表现出来的文化区域模型
            </span>
            <span>
              <p></p>
              心态文化层：大运河文化的价值观念、审美情趣、思维方式等精神文化、社会意识等概念，是大运河文化灵魂
            </span>
          </div>
        </div>
      </div>
      <!-- 大运河举办的文化活动主题：行走大运河模块 -->
      <h4 class="title_h4">大运河举办的文化活动主题：行走大运河</h4>
      <div class="foot_content">
        <div class="foot_box">
          <div>
            <img v-lazy="require('../assets/imgs/cultural_activity/orthogon2.webp')" alt="" />
          </div>
          <span>运河马拉松</span>
        </div>

        <div class="foot_box">
          <div>
            <img v-lazy="
                require('../assets/imgs/cultural_activity/orthogon2.1.webp')
              " alt="" />
          </div>
          <span>运河龙舟赛</span>
        </div>

        <div class="foot_box">
          <div>
            <img v-lazy="
                require('../assets/imgs/cultural_activity/orthogon2.2.webp')
              " alt="" />
          </div>
          <span>开展大运河中医药特色旅游</span>
        </div>

        <div class="foot_box">
          <div>
            <img v-lazy="
                require('../assets/imgs/cultural_activity/orthogon2.3.webp')
              " alt="" />
          </div>
          <span>培育旅游精品线路</span>
        </div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "optimization_algorithm",
  components: {
    Header,
    Footer,
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
</script>
<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }
  .banner {
    height: 600px;
    color: #fff;
    position: relative;
    .banner1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      // loading大小
      img[lazy='loading'] {
        width: 200px;
      }
      // 实际图片大小
      img[lazy='loaded'] {
        width: 100%;
        height: 100%;
      }
    }
    .banner2 {
      width: 100%;
      height: 600px;
      position: absolute;
      top: 0;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 130%);
      z-index: 1;
      .title {
        height: 67px;
        font-size: 48px;
        font-weight: 500;
        line-height: 67px;
        letter-spacing: 3px;
        text-align: left;
        position: absolute;
        left: 361px;
        top: 230px;
      }
      .introduce {
        width: 565px;
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        text-align: left;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        position: absolute;
        left: 361px;
        top: 321px;
      }
    }
  }
  .introduce,
  .title {
    animation: sport 0.5s;
    transform: translateY(0px);
  }
  //设置头部文字动画效果
  @keyframes sport {
    0% {
      transform: translateY(80px);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  //版心
  .title_h2 {
    font-size: 40px;
    font-weight: 500px;
    color: #333;
    letter-spacing: 1px;
    margin-bottom: 60px;
    margin-top: 80px;
  }
  .play {
    width: 1200px;
    height: 518px;
    margin: auto;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .play_item {
      width: 585px;
      height: 100%;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
      border-radius: 0px 0px 6px 6px;
      div {
        width: 100%;
        height: 420px;
        display: flex;
        justify-content: center;
        align-items: center;
        // loading大小
        img[lazy='loading'] {
          width: 150px;
        }
        // 实际图片大小
        img[lazy='loaded'] {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        margin-top: 35px;
      }
    }
  }

  .smriti {
    width: 100%;
    height: 677px;
    background: #f6f8fb;
    padding-top: 80px;
    .title_h3 {
      margin-bottom: 60px;
      font-size: 40px;
      font-weight: 500;
      color: #333333;
      letter-spacing: 1px;
    }
    .content {
      width: 1200px;
      height: 381px;
      margin: auto;
      display: flex;
      flex-direction: row;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      .images {
        width: 496px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // loading大小
        img[lazy='loading'] {
          width: 150px;
        }
        // 实际图片大小
        img[lazy='loaded'] {
          width: 100%;
          height: 100%;
        }
      }
      .spans {
        width: 704px;
        height: 100%;
        text-align: left;
        padding-top: 53px;
        span {
          display: inline-block;
          position: relative;
          width: 604px;
          font-size: 20px;
          font-weight: 400;
          color: #999999;
          margin-left: 64px;
          margin-bottom: 16px;
          p {
            position: absolute;
            top: 10px;
            left: -30px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #007aff;
          }
        }
      }
    }
  }
  .title_h4 {
    margin-top: 80px;
    margin-bottom: 60px;
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 1px;
  }
  .foot_content {
    width: 1200px;
    height: 291px;
    margin: auto;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .foot_box {
      width: 277px;
      height: 100%;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      div {
        width: 100%;
        height: 218px;
        display: flex;
        justify-content: center;
        align-items: center;
        // loading大小
        img[lazy='loading'] {
          width: 100px;
        }
        // 实际图片大小
        img[lazy='loaded'] {
          width: 100%;
          height: 100%;
        }
      }
      span {
        display: inline-block;
        user-select: none; //文本只读
        margin-top: 24px;
        height: 25px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
      }
    }
  }
</style>
